import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Container } from "react-bootstrap"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"

const PrivacyPage = () => {
  const Data = useStaticQuery(graphql`
    query {
      contentfulPrivacyPage {
        title
        content {
          json
        }
      }
    }
  `)

  return (
    <Layout>
      <SEO title="Privacy" />
      <Container className="mt-5">
        <h1>{Data.contentfulPrivacyPage.title}</h1>
        {documentToReactComponents(Data.contentfulPrivacyPage.content.json)}
      </Container>
    </Layout>
  )
}

export default PrivacyPage
